/*eslint-disable */
import {bus} from '@/main';  
export default {
  name: 'update-profile-info-popup',
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    updatedProfilePopupCloseMethod(){
      document.body.classList.remove('popup_open_body')
      bus.$emit('updatedProfilePopupBus',false)
    },
  }
}


